<template>
  <div id="sessoes-home" v-if="home.length == 0">
    <div class="my-4 text-center">
      <Skeleton width="50%" height="30px" class="inline-block" />
    </div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <Skeleton shape="circle" size="60px" class="inline-block va-middle" />
        <div class="inline-block va-middle ml-2" style="width: 70%">
          <Skeleton width="90%" height="30px" />
          <Skeleton width="70%" height="10px" class="mt-1" />
        </div>
        <Skeleton width="100%" height="200px" class="mt-2" />
        <div class="grid mt-1">
          <div class="col-6">
            <Skeleton width="75%" height="30px" />
          </div>
          <div class="col-6 text-right">
            <Skeleton width="75%" height="30px" class="inline-block" />
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <Skeleton shape="circle" size="60px" class="inline-block va-middle" />
        <div class="inline-block va-middle ml-2" style="width: 70%">
          <Skeleton width="90%" height="30px" />
          <Skeleton width="70%" height="10px" class="mt-1" />
        </div>
        <Skeleton width="100%" height="200px" class="mt-2" />
        <div class="grid mt-1">
          <div class="col-6">
            <Skeleton width="75%" height="30px" />
          </div>
          <div class="col-6 text-right">
            <Skeleton width="75%" height="30px" class="inline-block" />
          </div>
        </div>
      </div>
    </div>

    <div class="my-4 text-center">
      <Skeleton width="50%" height="30px" class="inline-block" />
    </div>

    <div class="grid">
      <div class="col-6 md:col-3">
        <Skeleton shape="circle" size="60px" class="inline-block va-middle" />
        <div class="inline-block va-middle ml-2" style="width: 70%">
          <Skeleton width="90%" height="30px" />
          <Skeleton width="70%" height="10px" class="mt-1" />
        </div>
        <Skeleton width="100%" height="150px" class="mt-2" />
        <div class="grid mt-1">
          <div class="col-6">
            <Skeleton width="75%" height="30px" />
          </div>
          <div class="col-6 text-right">
            <Skeleton width="75%" height="30px" class="inline-block" />
          </div>
        </div>
      </div>
      <div class="col-6 md:col-3">
        <Skeleton shape="circle" size="60px" class="inline-block va-middle" />
        <div class="inline-block va-middle ml-2" style="width: 70%">
          <Skeleton width="90%" height="30px" />
          <Skeleton width="70%" height="10px" class="mt-1" />
        </div>
        <Skeleton width="100%" height="150px" class="mt-2" />
        <div class="grid mt-1">
          <div class="col-6">
            <Skeleton width="75%" height="30px" />
          </div>
          <div class="col-6 text-right">
            <Skeleton width="75%" height="30px" class="inline-block" />
          </div>
        </div>
      </div>
      <div class="col-6 md:col-3">
        <Skeleton shape="circle" size="60px" class="inline-block va-middle" />
        <div class="inline-block va-middle ml-2" style="width: 70%">
          <Skeleton width="90%" height="30px" />
          <Skeleton width="70%" height="10px" class="mt-1" />
        </div>
        <Skeleton width="100%" height="150px" class="mt-2" />
        <div class="grid mt-1">
          <div class="col-6">
            <Skeleton width="75%" height="30px" />
          </div>
          <div class="col-6 text-right">
            <Skeleton width="75%" height="30px" class="inline-block" />
          </div>
        </div>
      </div>
      <div class="col-6 md:col-3">
        <Skeleton shape="circle" size="60px" class="inline-block va-middle" />
        <div class="inline-block va-middle ml-2" style="width: 70%">
          <Skeleton width="90%" height="30px" />
          <Skeleton width="70%" height="10px" class="mt-1" />
        </div>
        <Skeleton width="100%" height="150px" class="mt-2" />
        <div class="grid mt-1">
          <div class="col-6">
            <Skeleton width="75%" height="30px" />
          </div>
          <div class="col-6 text-right">
            <Skeleton width="75%" height="30px" class="inline-block" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="sessoes-home" v-else>
    <div
      id="categorias"
      class="fixed right-0 left-0 px-1 md:px-5 py-2"
      v-show="sessoes_visivel"
    >
      <div class="layout-main p-0">
        <Carousel
          :value="sessoes"
          :numVisible="5"
          :numScroll="4"
          :responsiveOptions="breakpointsCategorias"
          indicatorsContentClass="hidden"
        >
          <template #item="{ data }">
            <div class="text-center">
              <Button
                :label="data.label"
                :class="
                  sessao_visivel == data.id
                    ? 'sessao-visivel p-button-text'
                    : 'p-button-text'
                "
                @click="
                  irParaSessao(data);
                "
              />
            </div>
          </template>
        </Carousel>
      </div>
    </div>

    <div v-for="h of home" :key="h" :id="h.id">
      <h3 class="sessao-titulo text-2xl text-center" v-if="typeof h.nome == 'string' && h.nome != ''">{{ h.nome }}</h3>

      <div v-if="h.comportamento == 'MultiplosPorLinha'">
        <div class="grid mt-3">
          <div
            :class="
              'col-' +
              12 / h.qtdColunasMobile +
              ' md:col-' +
              12 / h.qtdColunasDesktop
            "
            v-for="i of h.itens"
            :key="i.id"
          >
            <component :is="h.componente" :value="i"></component>
          </div>
        </div>
      </div>

      <div v-else-if="h.comportamento == 'Carrocel'">
        <Carrocel
          :itens="h.itens"
          :breakpoints="breakpointsCarrocel[h.componente]"
          :componente="h.componente"
          class="mt-3"
        />
      </div>
    </div>

    <Button
      label="V carregar mais produtos... V"
      @click="carregaHomeParcial"
      class="w-full mt-4"
      style="background-color: var(--primaryBackgroundColor); border-color: var(--primaryBackgroundColor); font-size: 1.6em"
      v-show="carregandoHome == false && homeCompleta == false"
    />

    <ProgressBar class="mt-4" style="height: 10px" v-show="carregandoHome" mode="indeterminate" />

  </div>

  <Dialog v-if="displayProduto" :position="($utils.isMobileCheck())?'bottom':'center'" v-model:visible="displayProduto" :style="{width: '400px', 'max-width': '100%'}" :modal="true" :closable="true">
    <template #header>
      <h5 class="m-0 text-xl font-medium"><i class="pi pi-exclamation-circle" style="font-size: 1.3rem"></i> Detalhes do Produto</h5>
    </template>
    <AdicionarCarrinho :value="produto" @add="displayProduto=false" />
  </Dialog>

</template>

<script>
export default {
  data() {
    return {
      home: [],
      sessoes: [],
      sessao_visivel: "",
      sessoes_visivel: true,
      isMobile: document.body.classList.contains("is-touch"),
      scrollAnterior: 0,

      breakpointsCarrocel: {
        ProdutoResumido: [
          {
            largura: 4000,
            visivel: 4,
          },
          {
            largura: 900,
            visivel: 3,
          },
          {
            largura: 500,
            visivel: 1,
          },
        ],
        Status: [
          {
            largura: 4000,
            visivel: 6,
          },
          {
            largura: 900,
            visivel: 6,
          },
          {
            largura: 500,
            visivel: 3,
          },
        ],
      },

      breakpointsCategorias: [
        {
          breakpoint: "1024px",
          numVisible: 4,
          numScroll: 3,
        },
        {
          breakpoint: "700px",
          numVisible: 3,
          numScroll: 2,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 2,
          numScroll: 2,
        },
      ],
      carregandoHome: false,
      homeCompleta: false,

      displayProduto: false,
      produto: null,

    };
  },
  mounted() {
    document.body.classList.add("with-category-bar");
    this.scrollAnterior = window.scrollY;
    window.addEventListener("scroll", this.onScroll);
  },
  watch: {

    $route() {
      if (this.$route.name != "home") {
        document.body.classList.remove("with-category-bar");
        window.removeEventListener("scroll", this.onScroll);
      }
    },

    "$root.empresa": {
      handler: async function (empresa) {
        
        if (empresa != null) {

          this.carregaHomeInicial();
            
        }
      },
      deep: true,
      immediate: true,
    },

    displayProduto(displayProduto) {
      if(!displayProduto) {
        this.$router.push('/');
      }
    },
    
  },
  methods: {

    // OK
    onScroll() {

      const sessoes = this.$utils.getStdObject(this.sessoes).reverse();
      //const ultima_sessao = sessoes[this.home.length - 1]
      const sessao_visivel = sessoes.find(
        (e) => typeof e.scrollY != "undefined" && e.scrollY < window.scrollY
      );

      if (typeof sessao_visivel != "undefined") {
        this.sessao_visivel = sessao_visivel.id;
      }

      if (this.isMobile) {
        if (this.scrollAnterior > window.scrollY) {
          this.scrollAnterior = window.scrollY;
          this.sessoes_visivel = true;
        } else if (this.scrollAnterior < window.scrollY) {
          this.scrollAnterior = window.scrollY;
          this.sessoes_visivel = false;
        }
      }

      ///////////////////////////////////////////////////////////

      const obj_fim_pagina = document.getElementById("sessoes-home");
      const desconto_fim_pagina = window.outerHeight;
      const y_fim_pagina = obj_fim_pagina.offsetTop + obj_fim_pagina.offsetHeight - desconto_fim_pagina;

      if(window.scrollY > y_fim_pagina) { // FIM DA PÁGINA

        this.carregaHomeParcial();

      }

    },
    
    // OK
    irParaSessao(sessao) {
      if(typeof sessao.scrollY == "undefined") {
        this.$router.push('/busca/' + sessao.label + ' (categoria)');
        return;
      }
      this.$scrollTo("#" + sessao.id, 500, { offset: -120 });
    },

    // OK
    atualizarSessao(idh) {

      const self = this;

      const homeElement = self.home[idh];

      let wait = setInterval(() => {
        if (document.getElementById(homeElement.id) != null) {
          clearInterval(wait);

          const idx = self.sessoes.findIndex((e) => e.id == homeElement.id);
          const el = document.getElementById(homeElement.id);
          if(typeof self.sessoes[idx] != "undefined" && el != null)
            self.sessoes[idx].scrollY = el.offsetTop;

        }
      }, 500);

    },

    // OK
    async carregaHomeParcial() {

      if(this.homeCompleta || this.carregandoHome)
        return;

      this.carregandoHome = true;

      await new Promise(resolve => setTimeout(resolve, 500));

      const idh = this.home.length;

      if(typeof this.$root.conteudo[idh] != "undefined") {

        this.home.push(this.$root.conteudo[idh]);

        this.atualizarSessao(idh);

      } else {
        this.homeCompleta = true;
      }

      this.carregandoHome = false;

    },

    // OK
    async carregaHomeInicial() {

      this.carregandoHome = true;

      await this.$root.carregaConteudo();

      if(this.$root.conteudo.length > 0) { 

        // #region URL Produto //
        if(typeof this.$route.params != "undefined" && typeof this.$route.params.idProduto != "undefined") {

          for (const s of this.$root.conteudo) {
            for (const i of s.itens) {
              if(i.id == this.$route.params.idProduto) {
                this.produto = i;
                this.displayProduto = true;
                break;
              }
            }
            if(this.displayProduto)
              break;
          }

        }
        // #endregion //

        for (const c of this.$root.conteudo) {
          if(c.componente != "Status" && typeof c.nome == "string" && c.nome != "") {
            this.sessoes.push({
              id: c.id,
              label: c.nome,
            });
          }
        }

        if(this.$root.conteudo[0].componente == "Status") {
          this.home.push(this.$root.conteudo[0]);
          this.home.push(this.$root.conteudo[1]);
          this.atualizarSessao(1);
        } else {
          this.home.push(this.$root.conteudo[0]);
          this.atualizarSessao(0);
        }

      } else {
        alert("Página inicial indisponível!");
      }

      this.carregandoHome = false;

    }

  },
};
</script>

<style lang="scss" scoped>
.sessao-titulo {
  color: var(--secondaryColor, $secondaryColor);
}

#categorias {
  z-index: 998;
  top: 55px;
  background: var(--primaryBackgroundColor, $primaryBackgroundColor);
}

:deep(#categorias .p-carousel .p-carousel-content .p-carousel-prev),
:deep(#categorias .p-carousel .p-carousel-content .p-carousel-next) {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  height: 1.7rem;
  width: 1.7rem;
}

:deep(#categorias
    .p-carousel
    .p-carousel-content
    .p-carousel-prev:enabled:hover),
:deep(#categorias
    .p-carousel
    .p-carousel-content
    .p-carousel-next:enabled:hover) {
  color: var(--secondaryColor, $secondaryColor);
  background: var(--secondaryBackgroundColor, $secondaryBackgroundColor);
}

:deep(#categorias .p-button) {
  padding: 0.2rem 0.3rem;
}

:deep(#categorias .p-button.p-button-text) {
  color: white;
}

.sessao-visivel {
  color: var(--secondaryColor, $secondaryColor) !important;
  background: var(--secondaryBackgroundColor, $secondaryBackgroundColor);
}
</style>